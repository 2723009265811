<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 我的收藏
 * @Date: 2020-12-27 15:09:51
 * @LastEditors: OBKoro1
 * @LastEditTime: 2020-12-27 16:05:16
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/myCollection.vue
-->
<template>
  <div class="user_content">
    <van-cell-group>
      <van-cell is-link to="/Collectionhotel">
        <template #title>
          <img class="coll_img" src="@/assets/images/coll_1.png" alt="">
          <span>酒店</span>
        </template>
      </van-cell>
      <van-cell is-link>
        <template #title>
          <img class="coll_img" src="@/assets/images/coll_1.png" alt="">
          <span>商城</span>
        </template>
      </van-cell>
      <van-cell is-link>
        <template #title>
          <img class="coll_img" src="@/assets/images/coll_1.png" alt="">
          <span>线路</span>
        </template>
      </van-cell>
    </van-cell-group>
  </div>
</template>

<script>
import Vue from 'vue'
import { Cell, CellGroup, Toast } from 'vant'

Vue.use(Cell).use(CellGroup).use(Toast)

export default {
  data() {
    return {}
  },
  created() {

  },
  methods: {}
}
</script>

<style lang="less" scoped>
.user_content {
  width: 100%;
  height: auto;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f6f6;
  padding-bottom: 80px;

  .coll_img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
}

.van-cell__title {
  text-align: left;
}
</style>
